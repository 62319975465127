<div class="container cabinet">
  <div class="col-md-12 top10">
    <h3><i class="fa fa-diamond"></i> Admin Dashboard</h3>
    <hr>

    <mat-tab-group mat-align-tabs="start" animationDuration="0ms" class="mat-tab-header-pagination-controls-enabled" [selectedIndex]="selectedTab"  (selectedTabChange)="onTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-credit-card"></i>
          Transactions
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Transactions)">
          <app-admin-transaction></app-admin-transaction>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-file-pdf-o "></i>
          Order Results
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.OrderResults)">
          <app-admin-order-result></app-admin-order-result>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-envelope-o"></i>
          Email Log
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.EmailLog)">
          <app-admin-email></app-admin-email>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-users"></i>
          Users
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Users)">
          <app-admin-users></app-admin-users>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-medkit"></i>
          Products
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Products)">
          <app-admin-products></app-admin-products>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-envelope"></i>
          Keap Template
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.KeapTemplate)">
          <app-admin-keap-template></app-admin-keap-template>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-list "></i>
          Blog Articles
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.BlogArticles)">
          <app-admin-articles></app-admin-articles>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-edit"></i>
          Edit
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Edit)">
          <app-admin-edit></app-admin-edit>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-gift"></i>
          Promo code
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.PromoCode)">
          <app-admin-promo-code></app-admin-promo-code>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="pwnicon"></i>
          Pwn Orders
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.PwnOrders)">
          <app-pwn-orders></app-pwn-orders>
        </ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fa fa-sign-in"></i>
          Infusionsoft
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Infusionsoft)">
          <app-admin-infusionsoft></app-admin-infusionsoft>
        </ng-container>
        <!--<div class="container" ng-controller="CardController" ng-include="'/partials/UserCabinet/card.html'"></div>-->
      </mat-tab>
      <!--      <mat-tab>-->
      <!--        <ng-template mat-tab-label>-->
      <!--          <i class="fa fa-bar-chart"></i>-->
      <!--          E-Commerce-->
      <!--        </ng-template>-->
      <!--        <ng-container *ngIf="isSelected(AdminPages.ECommerce)">-->
      <!--          <app-admin-e-commerce></app-admin-e-commerce>-->
      <!--        </ng-container>-->
      <!--      </mat-tab>-->
      <mat-tab> <!-- Hide  Dependent Profiles *ngIf="false"-->
        <ng-template mat-tab-label>
          <i class="fa fa-list"></i>
          Categories
        </ng-template>
        <ng-container *ngIf="isSelected(AdminPages.Categories)">
          <app-admin-categories></app-admin-categories>
        </ng-container>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>

