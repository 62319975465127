<form [formGroup]="transactionForm" class="transactionForm">
  <div class="cabinetTabs">
    <div class="container-fluid">
      <div class="row transactions-filter">
        <div class="col-xs-12">
          <span>Transaction Status:</span>
          <!--<label class="checkbox-inline">-->
          <!--<input type="checkbox"-->
          <!--value="0"-->
          <!--ng-click="selectAll(status)"-->
          <!--ng-model="isAll">-->
          <!--All-->
          <!--</label>-->
          <label *ngFor="let status of statusList;let i = index" class="checkbox-inline">
            <input type="checkbox"
                   [checked]="status.checked"
                   (change)="toggleSelection($event, status);getOrderTaxonomy(1,$event,status)"
                   formControlName="status"
                   value="status.id"
                   #status
            >
            <!-- <input
            style="height: 15px;"
             class="form-control"
             type="checkbox"
             st
             value="status.id"
             (click)="toggleSelection(status)"
             formControlName="status"
             options="{debounce: 250}"> -->
            {{status.label}}
          </label>
          <label for="onlyFree" class="checkbox-inline">Only Free</label>
          <input type="checkbox" id="onlyFree" formControlName="onlyFree">
        </div>
        <div class="col-xs-3">
          <input class="input-group" style="height: 42px;" type="text"
                 placeholder="Search Transaction"
                 #search
                 formControlName="searchModel"
                 (change)="filterTransaction(search.value)">
          <label class="checkbox-inline">
            <input type="checkbox"
                   [checked]="isPromo"
                   (change)="toggleSelectionPromo()"
                   class="checkbox-inline"
                   formControlName="promoS"
                   value="isPromo"
                   #status
            >
            Promo
          </label>

        </div>



        <div class="col-xs-3">

          <p class="input-group">
            <mat-form-field appearance="fill" class="datePicker">
              <mat-label>Date start</mat-label>
              <input formControlName="createdDateFrom" matInput [matDatepicker]="createdDateFrom"
                     (dateChange)="dateChange()">
              <mat-datepicker-toggle matSuffix [for]="createdDateFrom">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #createdDateFrom></mat-datepicker>
            </mat-form-field>
          </p>
        </div>
        <div class="col-xs-3">
          <p class="input-group">
            <mat-form-field appearance="fill" class="datePicker">
              <mat-label>Date end</mat-label>
              <input formControlName="createdDateTo" matInput [matDatepicker]="createdDateTo"
                     (dateChange)="dateChange()">
              <mat-datepicker-toggle matSuffix [for]="createdDateTo">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #createdDateTo></mat-datepicker>
            </mat-form-field>
          </p>
        </div>
        <div class="col-xs-3 div-add-cart" >
          <div class="input-group">
            <input class="form-control"
                   type="text"
                   (change)="searchUser()"
                   class="form-control"
                   id="searchByName"
                   [formControl]="searchControl"
                   tooltip-placement="left" >
            <span class="input-group-addon">
                  <i class="glyphicon glyphicon-search"></i>
              </span>
          </div>
          <mat-form-field class="select-add-cart" appearance="fill" *ngIf="filteredOptions.length > 0">
            <mat-label>Select from Results</mat-label>
            <input type="text"
                   placeholder="Pick one"
                   aria-label="email"
                   matInput
                   (ngModelChange)="onChange($event)"
                   [formControl]="selectControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions" [value]="option.email" name="option">
                {{option.email}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button class="btn btn-warning" type="button"  style="margin: 10px 0;" (click)="addCart()">
            <i class="fa fa-plus-square-o"></i> Add Cart
          </button>
        </div>

      </div>
      <!-- <table class="table table-hover">
          <thead>
          <tr>
              <th>Name</th>
              <th>Order#</th>
              <th>Total</th>
              <th>Free</th>
              <th>Submitted Date</th>
              <th>Status</th>
              <th>Actions</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let order of orders; let i = index" >
              <td>{{order.userFullName}}</td>
              <td>{{order.number}}</td>
              <td>{{processingFee + order.subTotal }}</td>
              <td class="{'text-success' : (order.isPaymentSkipped)}">{{order.isPaymentSkipped ? 'YES' : 'NO'}}</td>
              <td>{{order.submittedDate | date: 'dd/MM/yyyy' }}</td>
              <td class="{'text-success' : (order.status % 16 > 0),
                          'text-warning' : (order.status % 16 == 0)}">
                  <i class="fa" class="{'fa-check-circle' : (order.status % 16 > 0),
                          'fa-exclamation-triangle' : (order.status % 16 == 0)}"></i>
                  {{orderStatuses[order.status] }}
              </td>
              <td>
                  <button class="btn btn-warning" (click)="cancelOrder(order, i)" [disabled]="order.status != 2 && order.status != 4">
                      <i class="fa fa-ban"></i> Cancel</button>
                  <button class="btn btn-danger" (click)="removeOrder(order, i)" [disabled]="order.status != 1 && order.status != 16">
                      <i class="fa fa-minus-circle"></i> Remove</button>
              </td>
          </tr>

          </tbody>

      </table> -->


      <table mat-table
             [dataSource]="dataSource"
             multiTemplateDataRows
             matSort
             class="mat-elevation-z8">
        <ng-container matColumnDef="{{'userFullName'}}" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let order" style="cursor: pointer;">{{order.userFullName}}</td>
        </ng-container>
        <ng-container matColumnDef="{{'number'}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order#</th>
          <td mat-cell *matCellDef="let order" style="cursor: pointer;">{{order.number}}</td>
        </ng-container>
        <ng-container matColumnDef="{{'subTotal'}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Value ($)</th>
          <td mat-cell *matCellDef="let order" style="cursor: pointer;">${{order.orderTotal}}</td>
        </ng-container>
        <ng-container matColumnDef="{{'promoCode'}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Promo Code</th>
          <td mat-cell *matCellDef="let order" style="cursor: pointer;">{{order.promoCode}}</td>
        </ng-container>
        <ng-container matColumnDef="{{'isPaymentSkipped'}}">
          <th mat-header-cell *matHeaderCellDef >Free</th>
          <td mat-cell *matCellDef="let order" class="{'text-success' : (order.isPaymentSkipped)}"
              style="cursor: pointer;">{{order.isPaymentSkipped ? 'YES' : 'NO'}}</td>
        </ng-container>
        <ng-container matColumnDef="{{'submittedDate'}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</th>
          <td mat-cell *matCellDef="let order"
              style="cursor: pointer;">{{order.submittedDate | date: 'MM/dd/yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="{{'Status'}}">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let order" class="{'text-success' : (order.status % 16 > 0),
                                    'text-warning' : (order.status % 16 == 0)}" style="cursor: pointer;">
            <i class="fa" class="{'fa-check-circle' : (order.status % 16 > 0),
                                    'fa-exclamation-triangle' : (order.status % 16 == 0)}"></i>
            {{orderStatuses[order.status] }}
          </td>
        </ng-container>
        <ng-container matColumnDef="{{'Actions'}}">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let order" style="cursor: pointer;">
            <button class="btn btn-warning" (click)="cancelOrder(order, i)"
                    [disabled]="order.status != 2 && order.status != 4" type="button">
              <i class="fa fa-ban"></i> Cancel
            </button>
            <button class="btn btn-danger" (click)="removeOrder(order, i)"
                    [disabled]="order.status != 1 && order.status != 16" type="button">
              <i class="fa fa-minus-circle"></i> Remove
            </button>
            <button class="btn btn-success" (click)="approveOrder(order, i)"
                    [disabled]=" order.status != 2 && order.status != 32 " type="button">
              <i class="fa fa-check-circle-o"></i> Approve
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let order" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail flex-column"
                 *ngIf="expandedElement === order"
                 [@detailExpand]="expandedElement && (order.id === expandedElement.id) ? 'expanded' : 'collapsed'">
              <div *ngIf="items.length > 0" style="display: flex; flex-direction: column; padding: '20px 0px'">
                <div *ngFor="let item of items">
                  <i class="fa fa-caret-right"></i>
                  <a href="javascript: void(0)">{{item.name}}</a>
                </div>

              </div>
              <div *ngIf="order?.status === 1">
                <div style="width: 100%;">
                  <app-product-tags [banks]="inputdata" [selectedChildren]="items"
                                    [type]="1"
                                    [multiple]="false"
                                    (productChange)="changeCart($event)">
                  </app-product-tags>
                </div>
                <div class="btn-change">
                  <button class="btn btn-success" (click)="saveCart(order)" type="button">
                    <i class="fa fa-plus-square-o"></i>
                    <i class="fa fa-minus-circle"></i> Change
                  </button>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let order; columns: columnsToDisplay;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElementOrder === order"
            (click)="expandRow(order)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                      (pageChange)="getOrderTaxonomy(page, $event)" [rotate]="true" [boundaryLinks]="true">
      </ngb-pagination>
    </div>
  </div>
</form>
