import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  cashOrders = {};
  constructor(private http: HttpClient) {}

  bindResultToOrder(orderId, clinicalReport): Observable<any> {
    return this.http.get(environment.base + `/api/order/results/bind?orderId=${orderId}&clinicalReport=${clinicalReport}`);
  }
  getAllOrderUnsolicitedResults(params): Observable<any> {
    return this.http.get(environment.base + `/api/order/results/unsolicited?${params}`);
  }

  browseOrders(param): Observable<any> {
    return this.http.get(environment.base + `/api/order/browse?${param}`);
  }

  addCategoryToCart(params) {
    return this.http
      .post(environment.base + '/api/order/add', params)
      .toPromise();
  }

  getOrder(id, refresh?): Observable<any> {
    return this.http.get<any>(environment.base + `/api/order/get/${id}`);
  }

  getOrderTaxonomy(params): Observable<any> {
    if (!params) {
      params = {};
    }
    // params.$orderby = 'CreatedDate desc';  // temporary sorting
    // return this.http.get(CONFIG.base + `/api/taxonomy/order`, {params}).toPromise();
    return this.http.get(environment.base + `/api/taxonomy/order?${params}`);
    // return this.http.get(CONFIG.base + `api/taxonomy/order?%24top=10&%24skip=0`).toPromise()
  }

  getOrderStatuses(): Observable<any> {
    return this.http.get<any>(environment.base + `/api/order/status/browse/62`);
  }

  approveOrder(orderId: string): Observable<any> {
    return this.http.get<any>(environment.base + `/api/order/approve/${orderId}`);
  }

  deleteItemInCart(params) {
    return this.http
      .delete(
        environment.base + `/api/order/${params.orderId}/item/${params.itemId}`
      )
      .toPromise();
  }

  submitCart(paymentData): Observable<any> {
    return this.http.post(
      environment.base + `/api/order/checkout`,
      paymentData
    );
  }

  stripeIntent(itemsId): Observable<any> {
    return this.http.post(environment.base + '/api/order/checkout/intent', {
      orderId: itemsId,
    });
  }

  stripeConfirm(model): Observable<any> {
    return this.http.post(
      environment.base + '/api/order/checkout/confirmation',
      model
    );
  }

  stripeFreeConfirm(orderId): Observable<any> {
    return this.http.post(
      environment.base + `/api/order/checkout/confirmation/free/${orderId}`,
      {}
    );
  }

  updateCart(params) {
    return this.http
      .put(environment.base + '/api/order/update/', params)
      .toPromise();
  }

  clearCart(params) {
    return this.http
      .delete(environment.base + `/api/order/${params.orderId}/items`)
      .toPromise();
  }

  getOrderResults(id): Observable<any> {
    return this.http.get<any>(environment.base + `/api/order/results/${id}`);
  }

  deleteOrder(id): Observable<any> {
    return this.http.delete<any>(environment.base + `/api/order/${id}`);
  }

  cancelOrder(id): Observable<any> {
    return this.http.get<any>(environment.base + `/api/order/cancel/${id}`);
  }

  getUserSendHistory(orderId): Observable<any> {
    return this.http.get(
      environment.base + `/api/order/results/share/get/${orderId}`
    );
  }
  shareModalSendEmail(orderId, clientId, type, contactTo): Observable<any> {
    return this.http.get<any>(
      environment.base +
      `/api/order/results/share/${orderId}/${clientId}/${type}?contactTo=${contactTo}`
    );
  }
  testGetClientId(): Observable<any> {
    return this.http.get(environment.base + `/api/order/results/download/`);
  }
}
