<form [formGroup]="resultForm" class="transactionForm">
  <div class="reportTable">
    <div class="cabinetTabs">
      <div class="container-fluid " style="padding-top: 20px">
        <div class="row">
          <div class="col-xs-3"> <input class="input-group" style="height: 42px;" type="text"
                                        placeholder="Search Result"
                                        #search
                                        formControlName="searchModel"
                                        (change)="changeSearchWord()"
          ></div>
          <div class="col-xs-3">

            <p class="input-group">
              <mat-form-field appearance="fill" class="datePicker">
                <mat-label>Date start</mat-label>
                <input formControlName="createdDateFrom" matInput [matDatepicker]="createdDateFrom"
                       (dateChange)="changeSearchWord()" >
                <mat-datepicker-toggle matSuffix [for]="createdDateFrom">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #createdDateFrom></mat-datepicker>
              </mat-form-field>
            </p>
          </div>
          <div class="col-xs-3">
            <p class="input-group">
              <mat-form-field appearance="fill" class="datePicker">
                <mat-label>Date end</mat-label>
                <input formControlName="createdDateTo" matInput [matDatepicker]="createdDateTo"
                       (dateChange)="changeSearchWord()" >
                <mat-datepicker-toggle matSuffix [for]="createdDateTo">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #createdDateTo></mat-datepicker>
              </mat-form-field>
            </p>
          </div>

        </div>
        <table mat-table [dataSource]="dataSource"  class="mat-elevation-z8 " multiTemplateDataRows matSort>
          <ng-container matColumnDef="clinicalReport">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Clinical Report</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.clinicalReport }}">{{ result.clinicalReport }}</td>
          </ng-container>

          <ng-container matColumnDef="personAccountNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.PersonAccountNumber }}">{{ result.personAccountNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.CreatedDate | date }}">{{ result.createdDate | date }}</td>
          </ng-container>

          <ng-container matColumnDef="personName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Patient Name</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.personFirstName + ' ' + result.personMiddleName + ' ' + result.personLastName }}">
              {{ result.personFirstName }} {{ result.personMiddleName }} {{ result.personLastName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="placerOrderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.placerOrderNumber }}">{{ result.placerOrderNumber }}</td>
          </ng-container>


          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let result" matTooltip="{{ result.name }}">{{ result.name }}</td>
          </ng-container>

          <ng-container matColumnDef="{{'Actions'}}">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let result" style="cursor: pointer;" class="action-column">
              <button (click)="downloadResult(result.clinicalReport)" class="btn btn-success"
                      type="button">
                <i class="fa fa-download"></i> Result
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let result" [attr.colspan]="displayedColumns.length" >
              <div class="example-element-detail flex-column"
                   *ngIf="expandedElement === result"
                   [@detailExpand]="expandedElement && (result.clinicalReport === expandedElement.clinicalReport) ? 'expanded' : 'collapsed'"
                   style="padding-top: 20px">
                <div class="row" style="padding-top: 10px">

                  <div class="col-xs-3"> <input class="input-group" style="height: 42px;" type="text"
                                                placeholder="Search Order"
                                                #search
                                                formControlName="searchModelOrder"
                                                (change)="getOrders()"

                  ></div>
                  <div class="col-xs-3">

                    <p class="input-group">
                      <mat-form-field appearance="fill" class="datePicker">
                        <mat-label>Date start</mat-label>
                        <input   (dateChange)="getOrders()" formControlName="createdDateFromOrder" matInput [matDatepicker]="createdDateFromOrder"
                        >
                        <mat-datepicker-toggle matSuffix [for]="createdDateFromOrder">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #createdDateFromOrder></mat-datepicker>
                      </mat-form-field>
                    </p>
                  </div>
                  <div class="col-xs-3">
                    <p class="input-group">
                      <mat-form-field appearance="fill" class="datePicker">
                        <mat-label>Date end</mat-label>
                        <input  (dateChange)="getOrders()" formControlName="createdDateToOrder" matInput [matDatepicker]="createdDateToOrder"
                        >
                        <mat-datepicker-toggle matSuffix [for]="createdDateToOrder">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #createdDateToOrder></mat-datepicker>
                      </mat-form-field>
                    </p>
                  </div>

                </div>
                <table mat-table
                       [dataSource]="dataSourceOrders"
                       multiTemplateDataRows
                       matSort
                       class="mat-elevation-z8">
                  <ng-container matColumnDef="{{'userFullName'}}" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let order" style="cursor: pointer;">{{order.userFullName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="{{'number'}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order#</th>
                    <td mat-cell *matCellDef="let order" style="cursor: pointer;">{{order.number}}</td>
                  </ng-container>
                  <ng-container matColumnDef="{{'submittedDate'}}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</th>
                    <td mat-cell *matCellDef="let order"
                        style="cursor: pointer;">{{order.submittedDate | date: 'MM/dd/yyyy' }}</td>
                  </ng-container>
                  <ng-container matColumnDef="{{'HealthcareId'}}">
                    <th mat-header-cell *matHeaderCellDef>HealthcareId</th>
                    <td mat-cell *matCellDef="let order" style="cursor: pointer;">
                      {{order.healthcareId}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="{{'Actions'}}">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let order" style="cursor: pointer;">
                      <button class="btn btn-success" (click)="bindResult(order)"
                              type="button">
                        <i class="fa fa-paperclip"></i> Bind
                      </button>
                    </td>
                  </ng-container>
                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetailOrder">

                    <td mat-cell *matCellDef="let order" [attr.colspan]="columnsToDisplayOrder.length">
                      <div class="example-element-detail flex-column"
                           *ngIf="expandedElementOrder === order"
                           [@detailExpand]="expandedElementOrder && (order.id === expandedElementOrder.id) ? 'expanded' : 'collapsed'">
                        <div *ngIf="items.length > 0" style="display: flex; flex-direction: column; padding: 20px 0px;">
                          <div *ngFor="let item of items">
                            <i class="fa fa-caret-right"></i>
                            <a href="javascript: void(0)">{{item.name}}</a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayOrder"></tr>
                  <tr mat-row *matRowDef="let order; columns: columnsToDisplayOrder;"
                      class="example-element-row"
                      [class.example-expanded-row]="expandedElementOrder === order"
                      (click)="expandRowOrder(order)">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetailOrder']" class="example-detail-row"></tr>
                </table>
                <ngb-pagination [collectionSize]="collectionSizeOrder" [(page)]="currentPageOrder" [maxSize]="5"
                                (pageChange)="getOrders(currentPageOrder, $event)"  [rotate]="true" [boundaryLinks]="true">
                </ngb-pagination>
              </div>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let result; columns: displayedColumns"
              (click)="toggleRow(result,$event)"
              [class.example-expanded-row]="expandedElement === result"
              class="clickable-row">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <ngb-pagination [collectionSize]="collectionSize" [(page)]="currentPage" [maxSize]="5"
                        [rotate]="true" [boundaryLinks]="true">
        </ngb-pagination>
      </div>
    </div>
  </div>
</form>
