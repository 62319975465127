export enum AdminPages {
  Transactions = 0,
  OrderResults = 1,
  EmailLog = 2,
  Users = 3,
  Products = 4,
  KeapTemplate = 5,
  BlogArticles = 6,
  Edit = 7,
  PromoCode = 8,
  PwnOrders = 9,
  Infusionsoft = 10,
  Categories = 11
}
