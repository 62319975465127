import {Component, OnInit, ViewChild} from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { MatTableDataSource } from '@angular/material/table';
import {UserData} from '../admin-transaction/admin-transaction.component';
import {ModalService} from '../../services/modal.service';
import {FormControl, FormGroup} from "@angular/forms";
import {MatSort} from "@angular/material/sort";
import moment from 'moment';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

export interface OrderHealthcareResult {
  clinicalReport: string;
  createdDate: Date;
  personAccountNumber: string;
  personFirstName: string;
  personMiddleName: string;
  personLastName: string;
  placerOrderNumber: string;
  reportType: string;
  name: string;
  reportSubject: string;
  person: string;
  orderHealthcareId: string | null;
  fileInfoId?: string | null;
}

export interface ListOrderHealthcareResult{
  entities : Array<OrderHealthcareResult>;
  totalCount: number;
}

@Component({
  selector: 'app-admin-order-result',
  templateUrl: './admin-order-result.component.html',
  styleUrls: ['./admin-order-result.component.scss']
})

export class AdminOrderResultComponent implements OnInit{
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['clinicalReport', 'personAccountNumber', 'createdDate', 'personName', 'placerOrderNumber', 'name', 'Actions'];
  columnsToDisplayOrder = [ 'userFullName' , 'number', 'submittedDate', 'HealthcareId', 'Actions'];
  collectionSize ;
  collectionSizeOrder;
  currentPage = 1;
  currentPageOrder = 1;
  dataSource: MatTableDataSource<OrderHealthcareResult>;
  dataSourceOrders: MatTableDataSource<UserData>;
  expandedElement: any | null = null;
  private _orders = [];
  resultForm: FormGroup;
  pageSize = 10;
  items = [];
  expandedElementOrder: any | null = null;
  constructor( private orderService: OrderService,
               private http: HttpClient,
               private modalService: ModalService) {

  }
  ngOnInit(): void {
    this.getOrderResults();

    this.resultForm = new FormGroup({
      searchModel: new FormControl(''),
      createdDateFrom: new FormControl(''),
      createdDateTo: new FormControl(''),
      searchModelOrder: new FormControl(''),
      createdDateFromOrder: new FormControl(''),
      createdDateToOrder: new FormControl(''),
    });
  }

  getOrderResults(){
    const path = `$orderby=CreatedDate+desc&$skip=0&$top=10`;

    this.orderService.getAllOrderUnsolicitedResults(path).subscribe(response => {
      this.dataSource = new MatTableDataSource(
        response.entities);
      this.dataSource.sort = this.sort;
      console.log(  response.entities)
      this.collectionSize = response.totalCount;
    });
  }

  getOrders(currentPage?, event?)
  {
    let skip = 0;
    if (currentPage) {
      skip = (currentPage ? (currentPage - 1) : (event.target.innerHTML.split(' ')[1] - 1)) * 10;
    }

    const searchWord = this.resultForm.value.searchModelOrder;

    const startDate = this.resultForm.value.createdDateFromOrder && moment(this.resultForm.value.createdDateFromOrder).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate = this.resultForm.value.createdDateToOrder && moment(this.resultForm.value.createdDateToOrder).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = startDate ? `&createdDateFrom=${ startDate }` : '';
    const endDatePath = endDate ? `&createdDateTo=${ endDate }` : '';

    let path = `$orderby=CreatedDate+desc&$skip=${skip}&$top=${ this.pageSize }&status=4&isHealthcareId=true`;

    if (searchWord !== '') {
      path += `&userFullName=${searchWord}`;
    }
    path += `${ startDatePath }${ endDatePath }`;

    this.orderService.getOrderTaxonomy(path).subscribe(result => {
      this._orders = result.items;
      this.collectionSizeOrder = result.count;
      this.dataSourceOrders = new MatTableDataSource(this._orders);
      this.currentPageOrder = currentPage ? currentPage : 1;
    });
  }
  toggleRow(element: OrderHealthcareResult,event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.closest('.action-column')) {
      event.stopPropagation();
      return;
    }

    if (this.expandedElement && this.expandedElement.clinicalReport === element.clinicalReport) {
      this.expandedElement = null;
      return;
    }
    this.expandedElement  = element;
    this.getOrders();
  }

  expandRowOrder(element){
    if (this.expandedElementOrder && this.expandedElementOrder.Id === element.Id) {
      this.expandedElementOrder = null;
      return;
    }
    this.orderService.getOrder(element.id, true).subscribe((res: any) => {
      this.items = res.items.map((el)=> el.product);
      this.expandedElementOrder = element;
    });
  }

  bindResult(order){
    this.orderService.bindResultToOrder(order.id, this.expandedElement.clinicalReport).subscribe((response) => {
      const index = this.dataSource.data.findIndex(item => item.clinicalReport === this.expandedElement.clinicalReport);
      if (index !== -1) {
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
      }
      this.modalService.showTopNotification(true, 'Successfully bind');
    });
  }

  downloadResult(orderId: string ){
    const baseDownloadLink =  environment.base +
      '/api/order/results/admin/download/' + orderId;

    this.http
      .get(baseDownloadLink, { responseType: 'blob' })
      .subscribe((result: any) => {
        if (!result || result.size === 0) {
          this.modalService.showTopNotification(false, 'The result is no longer available.');
          return;
        }
        const type =
          'result.pdf'.indexOf('hl7') > -1
            ? 'multipart/x-hl7-cdalevel-one'
            : 'application/pdf';
        const file = new Blob([result], { type });
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'result';
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      });
  }

  changeSearchWord(){
    let searchWord = this.resultForm.value.searchModel;
    const startDate = this.resultForm.value.createdDateFrom && moment(this.resultForm.value.createdDateFrom).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate = this.resultForm.value.createdDateTo && moment(this.resultForm.value.createdDateTo).format('YYYY-MM-DD') + 'T23:59:00.000Z';
    const startDatePath = startDate ? `&createdDateFrom=${ startDate }` : '';
    const endDatePath = endDate ? `&createdDateTo=${ endDate }` : '';

    let query = 'orderby=CreatedDate+desc&$skip=0&$top=10';

    if (searchWord !== '') {
      query += `&searchWord=${searchWord}`;
    }

    query += `${ startDatePath }${ endDatePath }`;

    this.orderService.getAllOrderUnsolicitedResults(query).subscribe((response) => {
      this.collectionSize = response.totalCount;
      this.dataSource = new MatTableDataSource(response.entities);
      this.dataSource.sort = this.sort;
    });
  }
}
