// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // base: 'https://www.grassrootslabs.io/api',
  // baseImageUrl: 'https://www.grassrootslabs.io/api/api/image/',
  // baseIconUrl: 'https://www.grassrootslabs.io/api/api/image/thumb/',

  // blogUrl: 'https://www.grassrootslabs.io/blog',
  // blogUrl: 'https://info.grassrootslabs.io/grassroots-labs-blog-temporarily-under-construction',
  base: 'https://test.grassrootslabs.com/api',
  baseUrl: 'https://staging.grassrootslabs.com',
  baseImageUrl: 'https://test.grassrootslabs.com/api/api/image/',
  baseIconUrl: 'https://test.grassrootslabs.com/api/api/image/thumb/',
  blogUrl: 'https://grassrootslabs.com/blog',
  covidIdList: ['d88e56be-a590-4b45-b188-aba40147a2f7'],
  covidIdListEmp: [
    '3fd0ba50-27e9-4eb5-af33-abdb00e30720',
    'abba89b7-f5ef-4cc4-9176-abde0148f00d',
    '2a2a630a-37ba-4c36-a885-abde014a350c',
    // prod ids
    'dba5e6a5-1203-4637-b653-abce00fb6764',
    'e8cdb352-a145-43b3-bd1a-abce00fc15d4',
    'a56e414d-2db6-447c-8b63-abde0149d001'
  ],

  stripeTest : 'pk_test_d7llWZh3dk2RHSF1lxfJZJmz',

  compressedData: 'compressedData123',

  questCodesSaliva: ['12345', '8138'],
  questCodesEmpWithoutThreeSteps: ['p1598', 'p1599'],
  questCodesEmp: ['p1598', 'p1599', '39448'],
  questCodes: ['39504'],
  processingFee: 28,
  physicianFee: 6,
  labDrawFee: 4,
  carouselInterval: 9000,
  version: 'Version 723 Test 18'
  // base: '',
  // baseImageUrl: 'http://grl-api-dev.softprise.net/api/image/',
  // baseIconUrl: 'http://grl-api-dev.softprise.net/api/image/thumb/',
  // blogUrl: 'http://blog-grl-dev.softprise.net',
  // carouselInterval: 9000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
